import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { DataResponse, Filter } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor(private httpClient: HttpClient) {}

  getFilters(id: number): Observable<DataResponse<Array<Filter>>> {
    return this.httpClient.get<any>(`${environment.urlBackEndApi}/filter/index/${id}`)
      .pipe(map((result) => result));
  }

  getFilter(id: number): Observable<DataResponse<Filter>> {
    return this.httpClient.get<any>(`${environment.urlBackEndApi}/filter/${id}`)
      .pipe(map((result) => result));
  }

  addFilter(data: Filter): Observable<DataResponse<Filter>> {
    return this.httpClient.post<any>(`${environment.urlBackEndApi}/filter/`, data)
      .pipe(map((result) => result));
  }

  updateFilter(data: Filter, id: number): Observable<DataResponse<Filter>> {
    return this.httpClient.put<any>(`${environment.urlBackEndApi}/filter/${id}`, data)
      .pipe(map((result) => result));
  }

  deleteFilter(id: number): Observable<null> {
    return this.httpClient.delete<any>(`${environment.urlBackEndApi}/filter/${id}`)
      .pipe(map((result) => result));
  }

  getJson(): Promise<Blob> {
    return this.httpClient.get(`${environment.urlBackEndApi}/filter/getJson`, {responseType: 'blob'}).toPromise();
  }

  updateJson(data): Observable<[]> {
    let formData: any = new FormData();

    formData.append("file", data.file);

    return this.httpClient.post<any>(`${environment.urlBackEndApi}/filter/updateJson`, formData)
      .pipe(map((result) => result));
  }
}
