import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { Question, DataResponse, CategoryQuestions } from '../../../interfaces';
import { AlertService } from '../../../services/alert.service';
import { ConfirmService } from '../../../services/confirm.service';
import { LangService } from '../../../services/lang.service';
import { QuestionService } from '../question.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  updateForm: FormGroup;
  questions: Question[];
  question: Question;
  errors;
  deleted = [];
  deletedFiles = [];
  isStartExist = false;
  lastTypeQuestion = '';
  categoriesQuestions: CategoryQuestions[];
  selectIsOpen = false;
  errorsTemplate;
  deletedExampleAnswers = [];
  deletedTypeValues = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private loaderService: NgxUiLoaderService,
    private alertService: AlertService,
    private questionService: QuestionService,
    private confirmService: ConfirmService,
    private langService: LangService,
  ) { }

  ngOnInit(): void {
    this.updateForm = this.fb.group({
      category_id: [null, Validators.required],
      next_question: [null],
      text_question: this.fb.group({
        ru: [''],
        en: [''],
        pl: [''],
      }),
      type_question: [null],
      is_start: [null],
      is_skip: [null],
      is_skip_next_question: [null],
      allow_my_answer: [null],
      type_my_answer: [null],
      can_add_files: [null],
      example_answers: this.fb.array([]),
      type_values: this.fb.array([]),
      answers: this.fb.array([]),
      files: this.fb.array([]),
    });
    this.activeRoute.params.subscribe((params) => {
      if (params && params.id) {
        this.getQuestion(params.id);
      }
    });
  }

  addLangs() {
    this.loaderService.start();
    this.questionService.getLangs().subscribe(success => {
      success?.forEach(answer => {
        this.answers.push(
          this.fb.group({
            next_question: [null],
            text_answer: this.fb.group({
              ru: [''],
              en: [answer.name],
              pl: [''],
            }),
            extra_message: [null],
          })
        );
      });
    }).add(() => this.loaderService.stop());
  }

  isStartClick() {
    if (this.isStartExist && !this.updateForm.get('is_start').value) {
      this.updateForm.controls.is_start.setValue(false);
      this.confirmService.confirm('Стартовый вопрос уже существует', 'Вы уверены что хотите поменять стартовый вопрос? Начальный вопрос может быть только один')
        .subscribe((confirmed) => {
          if (confirmed && !this.updateForm.get('is_start').value) {
            this.updateForm.get('is_start').setValue(true);
          }
        });
    }
  }

  patch() {
    this.updateForm.patchValue({
      category_id: this.question.category_id,
      next_question: this.question.next_question,
      is_skip_next_question: this.question.is_skip_next_question,
      text_question: this.question.text_question_translation,
      type_question: this.question.type_question,
      is_start: this.question.is_start,
      is_skip: this.question.is_skip,
      allow_my_answer: this.question.allow_my_answer,
      type_my_answer: this.question.type_my_answer,
      can_add_files: this.question.can_add_files,
    });
    this.lastTypeQuestion = this.question.type_question;
    this.example_answers.clear();
    this.question.example_answers_translation?.forEach(answer => {
      this.example_answers.push(
        this.fb.group({
          id: [answer.id],
          ru: [answer.ru],
          en: [answer.en],
          pl: [answer.pl],
        })
      );
    });
    this.type_values.clear();
    this.question.type_values_translation?.forEach(type_value => {
      this.type_values.push(
        this.fb.group({
          id: [type_value.id],
          ru: [type_value.ru],
          en: [type_value.en],
          pl: [type_value.pl],
        })
      );
    });
    this.files.clear();
    this.question.files?.forEach(file => {
      this.files.push(
        this.fb.control(file)
      );
    });
    if (this.updateForm.value.type_question == 'radio' ||
        this.updateForm.value.type_question == 'multipleChoise') {
      this.answers.clear();
      this.question.answers.forEach(answer => {
        this.answers.push(
          this.fb.group({
            id: [answer.id],
            next_question: [answer.next_question],
            text_answer: this.fb.group({
              id: [answer.text_answer_translation.id],
              ru: [answer.text_answer_translation.ru],
              en: [answer.text_answer_translation.en],
              pl: [answer.text_answer_translation.pl],
            }),
            extra_message: [answer.extra_message],
          })
        );
      });
    }

    this.updateForm.get('type_question').valueChanges.subscribe(value => {
      if (['input', 'textarea', 'range', 'int'].includes(value) &&
          ['radio', 'multipleChoise'].includes(this.lastTypeQuestion)) {
        this.deleted = [...this.deleted, ...this.answers.value.map(answer => answer.id || null).filter(id => id != null)];
        this.answers.clear();
      }
      if (['radio'].includes(this.lastTypeQuestion)) {
        this.answers.value.map((v, i) => this.answers.controls[i].patchValue({next_question: null}));
      }
      if (['int', 'input'].includes(this.lastTypeQuestion) && !['int', 'input'].includes(value)) {
        this.type_values.clear();
      }
      this.lastTypeQuestion = value;
    });
    this.updateForm.get('is_skip').valueChanges.subscribe(value => {
      if (!value) {
        this.updateForm.get('is_skip_next_question').patchValue(null);
      }
    });
    this.updateForm.get('allow_my_answer').valueChanges.subscribe(value => {
      if (!value) {
        this.updateForm.get('type_my_answer').patchValue(null);
      } else {
        this.updateForm.get('type_my_answer').patchValue(1);
      }
    });
  }

  getQuestion(id) {
    this.loaderService.start();
    this.questionService.getQuestion(id)
    .subscribe((success: DataResponse<Question>) => {
      this.question = success.data;
      this.getQuestions();
      this.patch();
    }, (error) => {
      this.alertService.danger(error);
      this.router.navigate(['questions']);
    }).add(() => this.loaderService.stop());
  }

  getQuestions() {
    this.loaderService.start();
    this.questionService.getQuestions(this.question.category_id)
      .subscribe((success: DataResponse<Question[]>) => {
        this.questions = success.data;
        let question = this.questions.find(question => question.is_start == true);
        if (question?.is_start) {
          this.isStartExist = true;
        }
      }, (error) => {
        this.alertService.danger(error);
        this.router.navigate(['questions']);
      }).add(() => this.loaderService.stop());
    this.loaderService.start();
    this.questionService.getCategoriesQuestions(this.question.category_id)
      .subscribe((success: DataResponse<CategoryQuestions[]>) => {
        this.categoriesQuestions = success.data;
      }, (error) => {
        this.alertService.danger(error);
        this.router.navigate(['questions']);
      }).add(() => this.loaderService.stop());
  }

  get answers() {
    return this.updateForm.get('answers') as FormArray;
  }

  get example_answers() {
    return this.updateForm.get('example_answers') as FormArray;
  }

  get type_values() {
    return this.updateForm.get('type_values') as FormArray;
  }

  get files() {
    return this.updateForm.get('files') as FormArray;
  }

  addAnswer() {
    this.answers.push(
      this.fb.group({
        next_question: [null],
        text_answer: this.fb.group({
          ru: [''],
          en: [''],
          pl: [''],
        }),
        extra_message: [null],
      })
    );
  }

  addExampleAnswer() {
    this.example_answers.push(
      this.fb.group({
        ru: [''],
        en: [''],
        pl: [''],
      })
    );
  }

  addTypeValue() {
    this.type_values.push(
      this.fb.group({
        ru: [''],
        en: [''],
        pl: [''],
      })
    );
  }

  update() {
    if (this.updateForm.invalid) {
      return;
    }
    this.loaderService.start();

    const data = {
      category_id: this.updateForm.value.category_id,
      next_question_id: this.updateForm.value.next_question?.id || null,
      is_skip_next_question_id: this.updateForm.value.is_skip_next_question?.id || null,
      text_question: this.updateForm.value.text_question,
      type_question: this.updateForm.value.type_question,
      is_start: this.updateForm.value.is_start,
      is_skip: this.updateForm.value.is_skip,
      allow_my_answer: this.updateForm.value.allow_my_answer,
      type_my_answer: this.updateForm.value.type_my_answer,
      can_add_files: this.updateForm.value.can_add_files,
      example_answers: this.updateForm.value.example_answers,
      type_values: this.updateForm.value.type_values,
      answers: [],
      deleted: this.deleted,
      files: this.updateForm.value.files.filter(file => !file.id),
      deletedFiles: this.deletedFiles,
    };

    data.example_answers = [...data.example_answers, ...this.deletedExampleAnswers.map(id => {
      return {
        id: id,
        is_deleted: true,
      };
    })];

    data.type_values = [...data.type_values, ...this.deletedTypeValues.map(id => {
      return {
        id: id,
        is_deleted: true,
      };
    })];

    data.answers = this.updateForm.value.answers.map(answer => {
      return {
        id: answer.id,
        next_question_id: answer.next_question?.id || null,
        text_answer: answer.text_answer,
        extra_message: answer.extra_message,
      };
    });

    this.questionService.updateQuestion(data, this.question.id)
      .subscribe((success: DataResponse<Question>) => {
        this.question = success.data;

        this.patch();

        this.questionService.categoriesQuestions?.data.forEach(category => {
          category.children.forEach(children => {
            children.questions.forEach((question, i, arr) => {
              if (question.id == this.question.id) {
                arr[i] = this.question;
              }
            });
          });
        });

        this.alertService.success('Вопрос обновлен.');
      }, (error) => {
        this.errors = error.error || error;
      }).add(() => this.loaderService.stop());
  }

  async delete() {
    this.loaderService.start();
    let questions = await this.questionService.getLinkedQuestions(this.question.id).toPromise()
      .then((questions: Question[]) => questions)
      .catch((error) => this.errors = error.error)
      .finally(() => this.loaderService.stop());

    let next_question = this.updateForm.value.next_question;
    let is_skip_next_question = this.updateForm.value.is_skip_next_question;
    let data = {
      question: this.question,
      questions,
      next_question,
      is_skip_next_question,
      categoriesQuestions: this.categoriesQuestions,
    };

    this.confirmService.confirm('Удаление вопроса', 'Вы действительно хотите удалить вопрос?', 'Удалить', 'Отмена', data)
      .subscribe((next_questions: any[]) => {
        if (next_questions) {
          let next_questions_data = {
            questions: [],
            answers: [],
          };

          next_questions.map(question => {
            if (question.answer_id) {
              next_questions_data.answers.push({
                id: question.answer_id,
                next_question_id: question.next_question?.id || null,
              });
            } else if (question.is_skip_next_question?.id || question.is_skip_next_question === null) {
              next_questions_data.questions.push({
                id: question.id,
                is_skip_next_question_id: question.is_skip_next_question?.id || null,
              });
            } else {
              next_questions_data.questions.push({
                id: question.id,
                next_question_id: question.next_question?.id || null,
              });
            }
          });

          this.loaderService.start();
          this.questionService.deleteQuestion(this.question.id, next_questions_data)
            .subscribe((success: null) => {
              this.alertService.success('Вопрос удален.');
              this.router.navigate(['questions']);
            }, (error) => {
              this.errors = error.error;
            }).add(() => this.loaderService.stop());
        }
      });
  }

  deleteAnswer(i: number) {
    if (this.answers.controls[i]?.value.id) {
      this.deleted.push(this.answers.controls[i].value.id);
    }
    this.answers.removeAt(i);
  }

  deleteExampleAnswer(i: number) {
    if (this.example_answers.controls[i]?.value.id) {
      this.deletedExampleAnswers.push(this.example_answers.controls[i].value.id);
    }
    this.example_answers.removeAt(i);
  }

  deleteTypeValue(i: number) {
    if (this.type_values.controls[i]?.value.id) {
      this.deletedTypeValues.push(this.type_values.controls[i].value.id);
    }
    this.type_values.removeAt(i);
  }

  updateFiles(event) {
    const target: HTMLInputElement = event.target;
    let files: File[] = event.target.files;
    for (const file of files) {
      this.files.push(
        this.fb.control(file)
      );
    }
    target.value = null;
  }

  deleteFile(i: number) {
    this.deletedFiles.push(this.files.controls[i].value.id);
    this.updateForm.controls.files.value.splice(i, 1);
  }
}
