import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { Category, DataResponse, Question } from '../../../interfaces';
import { ConfirmService } from '../../../services/confirm.service';
import { CategoryService } from '../../category/category.service';
import { QuestionService } from '../question.service';

@Component({
  selector: 'tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css']
})
export class TreeComponent implements OnInit {

  category: Category;
  questions: Question[];
  isCategoriesPage: boolean = this.router.url.includes('categories');
  isAdmin: boolean = JSON.parse(localStorage.getItem('userInfo')).role.includes('admin');
  iconCollapse: string = 'fa fa-caret-right';
  isStartExist = false;

  @ViewChildren('child') child: QueryList<TreeComponent>;

  @Input()
  set setCategory(category: Category) {
    this.category = category;
    // this.category.settings = {
    //   hidden: true
    // };

    this.questions = category.questions;

    if (this.category.is_final) {
      let question = this.questions.find(question => question.is_start == true);
      if (question?.is_start) {
        this.isStartExist = true;
      }
    }
    // if (this.questions.length > 0) {
    //   this.sortQuestions();
    // }
  }

  constructor(
    private fb: FormBuilder,
    private categoryService: CategoryService,
    private router: Router,
    private loaderService: NgxUiLoaderService,
    private confirmService: ConfirmService,
    private questionService: QuestionService,
  ) {}

  ngOnInit() {
    // if (this.category.is_final) {
    //   this.getQuestions(true);
    // }
  }

  async getCategory() {
    this.loaderService.start();
    const success = await this.categoryService.getCategory(this.category.id).toPromise();
    success.data.settings = {
      hidden: this.category.settings.hidden
    };

    this.category = success.data;
    this.loaderService.stop();
  }

  async toggleCollapseCategory(open = false) {
    if (!this.category.children && this.category.settings.hidden) {
      await this.getCategory();
    }
    if (this.category.children && this.category.children.length > 0 ||
        this.questions
    ) {
      this.category.settings.hidden = !this.category.settings.hidden;
    }
    if (open) {
      this.category.settings.hidden = false;
    }
  }

  closeCategory(category: Category) {
    category.settings.edit = false;
  }

  getQuestions(init = false) {
    this.loaderService.start();
    this.questionService.getQuestions(this.category.id)
      .subscribe((success: DataResponse<Question[]>) => {
        this.questions = success.data;
        if (this.questions.length > 0 && !init) {
          this.category.settings.hidden = false;
        }
        if (this.category.is_final) {
          let question = this.questions.find(question => question.is_start == true);
          if (question?.is_start) {
            this.isStartExist = true;
          }
        }
        if (this.questions.length > 0) {
          this.sortQuestions();
        }
      }).add(() => {
        this.loaderService.stop();
      });
  }

  sortQuestions() {
    let sortedArray = [];
    let index = this.questions.findIndex(question => question.is_start == true);
    let currentQuestion = this.questions.splice(index == -1 ? 0 : index, 1)[0];

    while (currentQuestion) {
      sortedArray.push(currentQuestion);
      let nextQuestionId = currentQuestion.next_question_id;
      index = this.questions.findIndex(question => question.id === nextQuestionId);

      if (index == -1) {
        this.questions = this.questions.sort((a, b) => {
          if (!a.next_question_id) {
            return 0;
          }

          return a.next_question_id - b.id;
        });

        let nextQuestion = this.questions[0];
        index = this.questions.findIndex(question => question.next_question_id === nextQuestion.id);
      }

      currentQuestion = this.questions.splice(index == -1 ? 0 : index, 1)[0];
    }

    this.questions = sortedArray;
  }

  selectQuestion(questionId) {
    this.questionService.selectedQuestion = questionId;
  }

}
