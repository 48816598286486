import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { Category, DataResponse } from '../../../interfaces';
import { CategoryService } from '../category.service';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  categories: Category[];
  editableCategory: Category;
  file;

  constructor(
    private categoryService: CategoryService,
    private loaderService: NgxUiLoaderService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.getCategories();
  }

  private getCategories() {
    this.loaderService.start();
    this.categoryService.getCategories()
      .subscribe((success: DataResponse<Category[]>) => {
        this.categories = success.data;
      }).add(() => {
        this.loaderService.stop();
      });
  }

  public addCategory() {
    this.loaderService.start();
    const categoryData: Category = {
      name: {
        ru: 'Категория',
        en: '',
        pl: '',
      },
      parent_id: null,
      is_final: false,
      is_active: false,
    };
    this.categoryService.addCategory(categoryData)
      .subscribe((success: DataResponse<Category>) => {
        this.categories.push(success.data);
      }).add(() => {
        this.loaderService.stop();
      });
  }

  updateChildrenCategory(updateCategory: Category, categories: Category[]) {
    if (categories === undefined) {
      let category = this.categories.find(category => category.id === updateCategory.parent_id);
      if (category !== undefined) {
        if (!category.children) {
          this.categoryService.getCategory(category.id)
            .subscribe((success: DataResponse<Category>) => {
              category = success.data;
              if (category.settings.hidden === undefined) {
                category.settings = {
                  hidden: false,
                  edit: false
                };
              } else {
                category.settings.hidden = false;
              }
            });
        } else {
          category.settings.hidden = false;
          category.children.push(updateCategory);
        }
      } else {
        this.categories?.forEach(category => {
          if (category.children && category.children.length > 0) {
            this.updateChildrenCategory(updateCategory, category.children);
          }
        });
      }
    } else {
      const category = categories.find(category => category.id === updateCategory.parent_id);
      if (category !== undefined) {
        if (!category.children) {
          this.categoryService.getCategory(category.id)
            .subscribe((success: DataResponse<Category>) => {
              category.children = success.data.children;
              if (category.settings.hidden === undefined) {
                category.settings = {
                  hidden: false,
                  edit: false
                };
              } else {
                category.settings.hidden = false;
              }
            });
        } else {
          category.settings.hidden = false;
          category.children.push(updateCategory);
        }
      } else {
        categories?.forEach(category => {
          if (category.children && category.children.length > 0) {
            this.updateChildrenCategory(updateCategory, category.children);
          }
        });
      }
    }
  }

  editCategory(category: Category) {
    this.editableCategory = category;
  }

  deleteChildrenCategory(children: Category) {
    const id = this.categories.find(category => category.id === children.parent_id)?.children.indexOf(children);
    if (id !== -1) {
      this.categories.find(category => category.id === children.parent_id)?.children.splice(id, 1);
    }
  }

  getJson() {
    this.loaderService.start();
    this.categoryService.getJson()
      .then((success: any) => {
        if (window) {
          const blob = window.URL.createObjectURL(success);
          const link = document.createElement('a');
          link.href = blob;
          link.download = 'categories.json';
          link.click();

          setTimeout(() => {
            window.URL.revokeObjectURL(blob);
            link.remove();
          }, 100);
        }
      }).finally(() => {
        this.loaderService.stop();
      });
  }

  addJson(event) {
    this.file = event.target.files[0];
  }

  updateJson() {
    this.loaderService.start();
    this.categoryService.updateJson({file: this.file})
      .subscribe((success: DataResponse<Category[]>) => {
        this.alertService.success('Файл успешно загружен.');
        this.file = null;
      }, (error) => {
        this.alertService.danger(error);
      }).add(() => {
        this.loaderService.stop();
      });
  }
}
