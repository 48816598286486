import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ConfirmComponent } from '../modules/shared/confirm/confirm.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor(private modalService: BsModalService) { }

  public confirm(title: string, message: string, btnOkText: string = 'Ок', btnCancelText: string = 'Отмена', data: any = null, windowSize: 'modal-sm' | 'modal-lg' = 'modal-lg') {
    const modalWindow = this.modalService.show(ConfirmComponent, {
      class: windowSize,
      initialState: {
        title,
        message,
        btnOkText,
        btnCancelText,
        data,
      }
    });
    return from(modalWindow.content.onClose);
  }
}
