import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { Category, DataResponse } from '../../../interfaces';
import { CategoryService } from '../category.service';
import { ConfirmService } from '../../../services/confirm.service';

@Component({
  selector: 'tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css']
})
export class TreeComponent implements OnInit {

  updateCategoryForm;
  category: Category;
  allCategories: Category[];
  isCategoriesPage: boolean = this.router.url.includes('categories');
  isAdmin: boolean = JSON.parse(localStorage.getItem('userInfo')).role.includes('admin');
  iconCollapse: string = 'fa fa-caret-right';
  productSubscription: Subscription;
  order = 'asc';
  sort: any = 'id';
  search: string;
  page = 1;
  pageSize = 10;
  totalItems: number;
  currentPage: number;
  deletedExampleAnswers = [];
  deletedSynonyms = [];

  @ViewChild('input') input: ElementRef;
  @ViewChildren('child') child: QueryList<TreeComponent>;

  @Input() index: number;
  @Input() parent: Category;
  @Input() categories: Category[];
  @Input() editableCategory: Category;
  @Input()
  set setCategory(category: Category) {
    this.category = category;
    this.category.settings = {
      edit: false,
      hidden: true
    };
  }
  @Output() deleteCategoryEvent = new EventEmitter<Category>();
  @Output() updateCategoryEvent = new EventEmitter<Category>();
  @Output() editableCategoryEvent = new EventEmitter<Category>();

  constructor(
    private fb: FormBuilder,
    private categoryService: CategoryService,
    private router: Router,
    private loaderService: NgxUiLoaderService,
    private confirmService: ConfirmService,
  ) {
    this.allCategories = this.categoryService.allCategories;
  }

  ngOnInit() {
    if (this.isCategoriesPage) {
      this.createForm();
    }
  }

  createForm() {
    this.updateCategoryForm = this.fb.group({
      name: this.fb.group({
        ru: [''],
        en: [''],
        pl: [''],
      }),
      parent: [null],
      order: [null],
      example_answers: this.fb.array([]),
      synonyms: this.fb.array([]),
    });
  }

  async getCategory() {
    this.loaderService.start();
    const success = await this.categoryService.getCategory(this.category.id).toPromise();
    success.data.settings = {
      // edit: false,
      hidden: this.category.settings.hidden
    };

    this.category = success.data;
    this.loaderService.stop();
  }

  getAllCategories() {
    this.loaderService.start();
    this.categoryService.getAllCategories()
      .subscribe((success: DataResponse<Category[]>) => {
        this.allCategories = success.data;
      }).add(() => {
        this.loaderService.stop();
      });
  }

  addCategory(category: Category) {
    if (!category.is_final) {
      const categoryData: Category = {
        name: {
          ru: 'Категория',
          en: '',
          pl: '',
        },
        parent_id: category.id,
        is_final: false,
        is_active: false,
      };

      this.loaderService.start();

      this.categoryService.addCategory(categoryData)
        .subscribe((success: DataResponse<Category>) => {
          success.data.settings = {
            edit: false,
            hidden: true
          };
          if (!category.children) {
            category.children = [];
          }
          this.toggleCollapseCategory(true);
          category.children.push(success.data);
          setTimeout(() => this.child.last.editCategory(), 0);
        }).add(() => {
          this.loaderService.stop();
        });
    }
  }

  editCategory() {
    if (this.editableCategory) {
      this.closeCategory(this.editableCategory);
    }
    this.getAllCategories();
    this.updateCategoryForm.controls.name.patchValue(this.category.name_translation);
    this.updateCategoryForm.controls.parent.setValue(this.category.parent_id);
    this.updateCategoryForm.controls.order.setValue(this.category.order);
    this.example_answers.clear();
    this.category.example_answers_translations?.forEach(answer => {
      this.example_answers.push(
        this.fb.group({
          id: [answer.id],
          ru: [answer.ru],
          en: [answer.en],
          pl: [answer.pl],
        })
      );
    });
    this.synonyms.clear();
    this.category.synonyms_translations?.forEach(synonym => {
      this.synonyms.push(
        this.fb.group({
          id: [synonym.id],
          ru: [synonym.ru],
          en: [synonym.en],
          pl: [synonym.pl],
        })
      );
    });
    this.editCategoryEvent(this.category);
    this.category.settings.edit = true;
    setTimeout(() => this.input.nativeElement.focus(), 0);
  }

  deleteCategory(category: Category) {
    this.confirmService.confirm('Удаление категории', 'Вы действительно хотите удалить категорию?', 'Удалить')
      .subscribe((confirmed) => {
        if (confirmed) {
          this.loaderService.start();
          this.categoryService.deleteCategory(category.id)
            .subscribe((success: null) => {
              this.category = success;
              this.deleteCategoryEvent.emit(category);
            }).add(() => {
              this.loaderService.stop();
            });
        }
      });
  }

  deleteChildrenCategory(category: Category) {
    const ids = [];
    this.category.children.forEach((children, index) => children.id === category.id ? ids.push(index) : null);
    if (ids.length > 0) {
      this.category.children.splice(ids[0], 1);
    }
    if (this.category.children.length === 0) {
      this.category.settings.hidden = true;
    }
  }

  updateChildrenCategory(category: Category) {
    this.updateCategoryEvent.emit(category);
  }

  editCategoryEvent(category: Category) {
    this.editableCategoryEvent.emit(category);
  }

  updateCategory(category: Category) {
    if (this.updateCategoryForm.valid) {
      const categoryData: Category | any = {
        name: this.updateCategoryForm.get('name').value || category.name,
        example_answers: this.updateCategoryForm.value.example_answers,
        synonyms: this.updateCategoryForm.value.synonyms,
        order: this.updateCategoryForm.value.order,
      };

      categoryData.example_answers = [...categoryData.example_answers, ...this.deletedExampleAnswers.map((id: number) => {
        return {
          id: id,
          is_deleted: true,
        };
      })];

      categoryData.synonyms = [...categoryData.synonyms, ...this.deletedSynonyms.map((id: number) => {
        return {
          id: id,
          is_deleted: true,
        };
      })];

      if (this.updateCategoryForm.get('parent').value != category.parent_id) {
        categoryData.parent_id = this.updateCategoryForm.get('parent').value;
      }
      this.categoryService.updateCategory(categoryData, category.id)
        .subscribe(async (success: DataResponse<Category>) => {
          category.name = success.data.name;
          category.name_translation = success.data.name_translation;
          category.order = success.data.order;
          category.example_answers = success.data.example_answers;
          category.synonyms = success.data.synonyms;
          category.example_answers_translations = success.data.example_answers_translations;
          category.synonyms_translations = success.data.synonyms_translations;

          let sortCategories = this?.categories || this?.parent.children;

          sortCategories.sort((a, b) => {
            if (a.order === null && b.order === null) {
              return a.id - b.id;
            } else if (a.order === null && b.order !== null) {
              return a.id - b.order;
            } else if (a.order !== null && b.order === null) {
              return a.order - b.id;
            } else {
              return a.order - b.order;
            }
          });

          if (this.updateCategoryForm.get('parent').value != category.parent_id) {
            category.parent_id = success.data.parent_id;
            this.updateChildrenCategory(category);
            this.deleteCategoryEvent.emit(category);
          }
        });
      category.settings.edit = false;
      this.deletedExampleAnswers = [];
      this.deletedSynonyms = [];
    }
  }

  async toggleCollapseCategory(open = false) {
    if (!this.category.children && this.category.settings.hidden) {
      await this.getCategory();
    }
    if (this.category.children && this.category.children.length > 0) {
      this.category.settings.hidden = !this.category.settings.hidden;
    }
    if (open) {
      this.category.settings.hidden = false;
    }
  }

  toggleFinal() {
    const categoryData: Category = {
      is_final: !this.category.is_final
    };
    this.categoryService.updateCategory(categoryData, this.category.id)
      .subscribe((success: DataResponse<Category>) => {
        this.category.is_final = !this.category.is_final;
      });
  }

  toggleActive() {
    const categoryData: Category = {
      is_active: !this.category.is_active
    };
    this.categoryService.updateCategory(categoryData, this.category.id)
      .subscribe((success: DataResponse<Category>) => {
        this.category.is_active = !this.category.is_active;
      });
  }

  closeCategory(category: Category) {
    category.settings.edit = false;
  }

  sortCol(sort) {
    if (this.sort === sort) {
      this.order === 'asc' ? this.order = 'desc' : this.order = 'asc';
    } else {
      this.sort = sort;
    }
  }

  searchChange(e) {
    this.currentPage = 1;
  }

  pageChanged(event: any) {
    this.page = event.page;
  }

  get example_answers() {
    return this.updateCategoryForm.get('example_answers') as FormArray;
  }

  get synonyms() {
    return this.updateCategoryForm.get('synonyms') as FormArray;
  }

  addExampleAnswer() {
    this.example_answers.push(
      this.fb.group({
        ru: [''],
        en: [''],
        pl: [''],
      })
    );
  }

  deleteExampleAnswer(i: number) {
    if (this.example_answers.controls[i]?.value.id) {
      this.deletedExampleAnswers.push(this.example_answers.controls[i].value.id);
    }
    this.example_answers.removeAt(i);
  }

  addSynonym() {
    this.synonyms.push(
      this.fb.group({
        ru: [''],
        en: [''],
        pl: [''],
      })
    );
  }

  deleteSynonym(i: number) {
    if (this.synonyms.controls[i]?.value.id) {
      this.deletedSynonyms.push(this.synonyms.controls[i].value.id);
    }
    this.synonyms.removeAt(i);
  }

  categoryUp() {
    let topCategory = (this?.categories || this?.parent.children)[this.index - 1];

    let categoryData: Category = {
      order: topCategory.order || topCategory.id,
    };

    this.loaderService.start();

    this.categoryService.updateCategory(categoryData, this.category.id)
      .subscribe((thisCategoryData: DataResponse<Category>) => {
        let tempOrder = this.category.order || this.category.id;

        let categoryData: Category = {
          order: tempOrder,
        };

        this.loaderService.start();

        this.categoryService.updateCategory(categoryData, topCategory.id)
          .subscribe((success: DataResponse<Category>) => {
            topCategory.order = success.data.order;

            let sortCategories = this?.categories || this?.parent.children;
            let cat = sortCategories.splice(this.index, 1)[0];

            cat.order = thisCategoryData.data.order;

            sortCategories.splice(this.index - 1, null, cat);
          }).add(() => this.loaderService.stop());
      }).add(() => this.loaderService.stop());
  }

  categoryDown() {
    let downCategory = (this?.categories || this?.parent.children)[this.index + 1];

    const categoryData: Category = {
      order: downCategory.order || downCategory.id,
    };

    this.loaderService.start();

    this.categoryService.updateCategory(categoryData, this.category.id)
      .subscribe((thisCategoryData: DataResponse<Category>) => {
        let tempOrder = this.category.order || this.category.id;

        const categoryData: Category = {
          order: tempOrder,
        };

        this.loaderService.start();

        this.categoryService.updateCategory(categoryData, downCategory.id)
          .subscribe((success: DataResponse<Category>) => {
            downCategory.order = success.data.order;

            let sortCategories = this?.categories || this?.parent.children;
            let cat = sortCategories.splice(this.index, 1)[0];

            cat.order = thisCategoryData.data.order;

            sortCategories.splice(this.index + 1, null, cat);
          }).add(() => this.loaderService.stop());
      }).add(() => this.loaderService.stop());
  }

}
