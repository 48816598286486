import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Категории',
    url: 'categories',
    icon: 'icon-layers',
  },
  {
    name: 'Вопросы',
    url: 'questions',
    icon: 'icon-layers',
  },
  {
    name: 'Фильтры',
    url: 'filters',
    icon: 'icon-layers',
  },
  {
    name: 'Заказы',
    url: 'tasks',
    icon: 'icon-layers',
  },
  {
    name: 'Оспоренные заказы',
    url: 'disputed-tasks',
    icon: 'icon-layers',
  },
  {
    name: 'Чаты',
    url: 'chats',
    icon: 'icon-layers',
  },
  {
    name: 'Пользователи',
    url: 'users',
    icon: 'icon-layers',
  },
  {
    name: 'Жалобы',
    url: 'reports',
    icon: 'icon-layers',
  },
  {
    name: 'Баны',
    url: 'bans',
    icon: 'icon-layers',
  },
  {
    name: 'Письма',
    url: 'mails',
    icon: 'icon-layers',
  },
]