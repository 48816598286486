import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { DataResponse, Filter, Question } from '../../../interfaces';
import { AlertService } from '../../../services/alert.service';
import { ConfirmService } from '../../../services/confirm.service';
import { QuestionService } from '../../question/question.service';
import { FilterService } from '../filter.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  addForm: FormGroup;
  questions: Question[];
  errors;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private loaderService: NgxUiLoaderService,
    private alertService: AlertService,
    private confirmService: ConfirmService,
    private questionService: QuestionService,
    private filterService: FilterService,
  ) { }

  ngOnInit(): void {
    this.loaderService.start();
    this.addForm = this.fb.group({
      category_id: [null, Validators.required],
      question: [null],
      text: [null],
      need_skill: [null],
      text_skill: [null],
      is_active: [null],
      answers: this.fb.array([]),
    });

    this.addForm.get('question').valueChanges.subscribe(question => {
      if (question.type_question == 'radio' ||
          question.type_question == 'multipleChoise') {
        let answers = question.answers.map(answer => {
          let formAnswer = this.fb.group({
            'answer_id': answer.id,
            'is_checked': true,
            'text_answer': [{value: answer.text_answer, disabled: true}],
            'text': answer.text_answer,
          });
          return formAnswer;
        });
        this.addForm.setControl('answers', this.fb.array(answers));
      } else {
        this.addForm.setControl('answers', this.fb.array([]));
      }
      this.addForm.patchValue({
        'text': question.text_question,
      });
    });

    this.addForm.get('need_skill').valueChanges.subscribe(value => {
      if (!value) {
        this.addForm.get('text_skill').patchValue(null);
        this.addForm.get('is_active').patchValue(null);
      }
    });

    this.activeRoute.params.subscribe((params) => {
      if (params && params.id) {
        this.addForm.patchValue({category_id: params.id});
        this.questionService.getQuestions(params.id)
          .subscribe((success: DataResponse<Question[]>) => {
            this.questions = success.data;
          }, (error) => {
            this.alertService.danger(error);
            this.router.navigate(['filters']);
          });
      }
    });
    this.loaderService.stop();
  }

  add() {
    if (this.addForm.invalid) {
      return;
    }
    this.loaderService.start();

    const data = {
      category_id: this.addForm.value.category_id,
      question_id: this.addForm.value.question?.id,
      text: this.addForm.value.text,
      text_skill: this.addForm.value.text_skill,
      is_active: this.addForm.value.is_active,
      answers: this.addForm.value.answers,
    };

    this.filterService.addFilter(data)
      .subscribe((success: DataResponse<Filter>) => {
        this.alertService.success('Фильтр добавлен.');
        this.router.navigate(['filters']);
      }, (error) => {
        this.errors = error.error;
      });
    this.loaderService.stop();
  }

  get answers() {
    return this.addForm.get('answers') as FormArray;
  }

}
