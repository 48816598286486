import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AlertComponent } from './alert/alert.component';
import { ModalComponent } from './modal/modal.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { QuestionSelectComponent } from '../../components/question-select/question-select.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

/**
 * Подгрузка файлов с локализацией
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AlertComponent,
    ModalComponent,
    ConfirmComponent,
    QuestionSelectComponent,
  ],
  imports: [
    CommonModule,
    AlertModule,
    ModalModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ru',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
    AlertComponent,
    ModalComponent,
    ConfirmComponent,
    TranslateModule,
    QuestionSelectComponent,
    TooltipModule,
  ],
  providers: [
    TranslatePipe,
  ],
})
export class SharedModule { }
