import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { LoginData, LoginResponse, RecoveryData, RefreshToken, ResetData, User, VerifyData } from '../../interfaces';
import * as jwt_decode from 'jwt-decode';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly JWT_TOKEN = 'authToken';
  private readonly REFRESH_TOKEN = 'refreshToken';
  private readonly USER_INFO = 'userInfo';

  constructor(
    private http: HttpClient,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private httpClient: HttpClient
  ) { }

  login(user: LoginData): Observable<LoginResponse> {
    return this.http.post<any>(`${environment.urlBackEndApi}/auth/login`, user)
      .pipe(
        tap((response: LoginResponse) => {
          this.doLoginUser(response);
        }),
        catchError(this.handleError)
      );
  }

  logout() {
    this.ngxService.startLoader('auth-loader');
    return this.http.post<any>(`${environment.urlBackEndApi}/auth/logout`, null)
      .pipe(
        tap(() => this.doLogoutUser()),
        mapTo(true),
        catchError(this.handleLogoutError.bind(this))
      );
  }

  recovery(recovery: RecoveryData): Observable<any> {
    return this.http.post<any>(`${environment.urlBackEndApi}/auth/recovery`, recovery)
      .pipe(
        catchError(this.handleRecoveryError)
      );
  }

  reset(reset: ResetData): Observable<any> {
    return this.http.post<any>(`${environment.urlBackEndApi}/auth/reset`, reset)
      .pipe(
        catchError(this.handleResetError)
      );
  }

  verifyEmail(verify: VerifyData) {
    let id = verify.id;
    if (!id) {
      id = verify.queryURL.slice(3);
    }

    return this.http.get<any>(`${environment.urlBackEndApi}/email/verify/${id}`, {
      params: {
        signature: verify.signature,
        expires: verify.expires,
      }
    })
      .pipe(
        catchError(this.handleResetError)
      );
  }

  isLoggedIn() {
    return !!this.getJwtToken() && this.checkTokenExpired();
  }

  me() {
    return this.httpClient.post<any>(`${environment.urlBackEndApi}/auth/me`, {})
      .pipe(
        map((result) => {
          return result.data;
        }),
      );
  }

  refreshToken() {
    const rt: RefreshToken = {
      refresh_token: this.getRefreshToken()
    };
    return this.http.post<any>(`${environment.urlBackEndApi}/auth/refresh`, rt)
      .pipe(
        tap((response: LoginResponse) => this.storeTokens(response))
      );
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  public checkTokenExpired(): boolean {
    const tokenData = jwt_decode(this.getJwtToken());
    if (!tokenData) {
      return false;
    } else {
      //  check token expiration date
      return tokenData.exp > Date.now() / 1000;
    }
  }

  removeTokensAndRedirect() {
    this.removeTokens();
    this.router.navigate(['/login']);
  }

  private doLoginUser(response: LoginResponse) {
    this.storeTokens(response);
  }

  private doLogoutUser() {
    this.removeTokens();
    this.ngxService.stopLoader('auth-loader');
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeTokens(response: LoginResponse) {
    if (response) {
      localStorage.setItem(this.JWT_TOKEN, response.access_token);
      localStorage.setItem(this.REFRESH_TOKEN, response.refresh_token);
      localStorage.setItem(this.USER_INFO, JSON.stringify(response.user));
    } else {
      localStorage.clear();
    }
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.USER_INFO);
  }

  private handleError() {
    return throwError('Ошибка! Возможно введены неверные данные.');
  }

  private handleLogoutError() {
    this.removeTokensAndRedirect();
    return throwError('Ошибка при выходе из системы!');
  }

  private handleRegistrationError(error: HttpErrorResponse) {
    const errors = error.error.errors;
    let message = '';
    if (errors.email) {
      message += errors.email;
    }
    if (errors.c_password) {
      message += " " + errors.c_password;
    }
    return throwError(message);
  }

  private handleRecoveryError(error: HttpErrorResponse) {
    return throwError(error.message);
  }

  private handleResetError(error: HttpErrorResponse) {
    const errors = error.error.errors;
    let message = '';
    if (error.message) {
      message += error.message;
    } else {
      if (error.error) {
        message += error.error.message;
      }
      if (errors && errors.password) {
        message += errors.password.join(' ');
      }
    }
    return throwError(message);
  }

  public getUserInfo() {
    return JSON.parse(localStorage.getItem(this.USER_INFO));
  }

  public isAdmin() {
    const userInfo = this.getUserInfo();
    if (userInfo) {
      return userInfo.role.includes('admin');
    }
    return false;
  }

  public isTeacher() {
    const userInfo = this.getUserInfo();
    if (userInfo) {
      return userInfo.role.includes('teacher');
    }
    return false;
  }

  public resendVerificationEmail() {
    return this.http.get<any>(`${environment.urlBackEndApi}/email/resend`)
      .pipe(
        catchError(this.handleResetError)
      );
  }

  public updateUserInfo(newUserInfo: User) {
    localStorage.setItem(this.USER_INFO, JSON.stringify(newUserInfo));
  }
}
