import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';

export const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard, AdminGuard],
    children: [
      {
        path: 'categories',
        loadChildren: () => import('./modules/category/category.module').then(m => m.CategoryModule)
      },
      {
        path: 'questions',
        loadChildren: () => import('./modules/question/question.module').then(m => m.QuestionModule)
      },
      {
        path: 'filters',
        loadChildren: () => import('./modules/filter/filter.module').then(m => m.FilterModule)
      },
      {
        path: 'tasks',
        loadChildren: () => import('./modules/task/task.module').then(m => m.TaskModule)
      },
      {
        path: 'disputed-tasks',
        loadChildren: () => import('./modules/disputed-task/disputed-task.module').then(m => m.DisputedTaskModule)
      },
      {
        path: 'chats',
        loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'bans',
        loadChildren: () => import('./modules/ban/ban.module').then(m => m.BanModule)
      },
      {
        path: 'mails',
        loadChildren: () => import('./modules/mail/mail.module').then(m => m.MailModule)
      },
    ]
  },
  { path: '**', component: P404Component },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
