import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Lang } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  constructor(private httpClient: HttpClient) {}

  getLangs(): Observable<Array<Lang>> {
    return this.httpClient.get<any>(`${environment.urlBackEndApi}/lang`)
      .pipe(map((result) => result));
  }
}
