import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '../modules/auth/auth.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { LoginResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public authService: AuthService,
  ) { }

  // Добавляем токен в заголовок к каждому запросу Http
  private static addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Механизм JWT токенов только для нашего API
    if (!request.url.includes('api/')) {
      return next.handle(request);
    }

    // Добавляем существующий токен к запросу
    if (this.authService.getJwtToken()) {
      request = TokenInterceptor.addToken(request, this.authService.getJwtToken());
    }

    return next.handle(request)
      .pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            // скорее всего токен протух или вообще не задан
            if (request.url.includes('auth/refresh')) {
              this.authService.removeTokensAndRedirect();
              return throwError('Невозможно получить свежий токен, выходим!');
            }

            // На странице авторизации не нужно отправять запрос на рефреш токена
            if (request.url.includes('login')) {
              return throwError('');
            }

            return this.handle401Error(request, next);
          } else {
            const errorMessage: any = error.error instanceof ErrorEvent
              ? `Error: ${error.error.message}`
              : {status: error.status, message: error.error.message, error: error.error.errors};
            return throwError(errorMessage);
          }
        })
      );
  }

  // Обработка 401 статуса (не авторизованный пользователь), пытаемся бесшовно авторизоваться с помошью рефреш токена
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((response: LoginResponse) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response.access_token);

          return next.handle(TokenInterceptor.addToken(request, response.access_token));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(TokenInterceptor.addToken(request, jwt));
        })
      );
    }
  }
}
