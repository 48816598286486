import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CategoryQuestions } from '../../interfaces';

@Component({
  selector: 'app-question-select',
  templateUrl: './question-select.component.html',
  styleUrls: ['./question-select.component.scss']
})
export class QuestionSelectComponent implements OnInit {

  selectIsOpen = false;

  @Input() control: FormControl;
  @Input() categoriesQuestions: CategoryQuestions[];
  @Input() hideText = false;
  @Input() text = 'Следующий вопрос';

  constructor() { }

  ngOnInit(): void {}

  checkIsStart(category): boolean {
    let question = category.questions?.find(question => question.is_start != true);

    if (question) {
      return true;
    }

    let arr: boolean[] = [];

    for (let child of category.children) {
      arr.push(this.checkIsStart(child));
    }
    return arr.includes(true);
  }

}
