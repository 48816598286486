import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from '../../../services/alert.service';


const DEFAULT_ALERT_TIMEOUT = 3000;

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit, OnDestroy {

  public text: string;
  public type = 'success';
  staticAlertClosed = true;

  aSub: Subscription;

  constructor(
    private alertService: AlertService,
  ) {
  }

  ngOnInit(): void {
    this.aSub = this.alertService.alert$.subscribe(alert => {
      this.text = alert.text.message ? alert.text.message : alert.text;
      this.type = alert.type;
      this.staticAlertClosed = false;
      setTimeout(() => this.staticAlertClosed = true, DEFAULT_ALERT_TIMEOUT);
    });
  }

  ngOnDestroy(): void {
    if (this.aSub) {
      this.aSub.unsubscribe();
    }
  }

}
