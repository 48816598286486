import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export type AlertType = 'success' | 'warning' | 'danger' | 'info' | 'primary' | 'secondary' | 'light' | 'dark';

export interface Alert {
  type: AlertType;
  text: any;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public alert$ = new Subject<Alert>();

  success(text: string) {
    this.alert$.next({type: 'success', text});
  }

  warning(text: string) {
    this.alert$.next({type: 'warning', text});
  }

  danger(text: string) {
    this.alert$.next({type: 'danger', text});
  }

  info(text: string) {
    this.alert$.next({type: 'info', text});
  }

  primary(text: string) {
    this.alert$.next({type: 'primary', text});
  }

  secondary(text: string) {
    this.alert$.next({type: 'secondary', text});
  }

  light(text: string) {
    this.alert$.next({type: 'light', text});
  }

  dark(text: string) {
    this.alert$.next({type: 'dark', text});
  }
}
