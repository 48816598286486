import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Category, DataResponse } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  allCategories: Category [];

  constructor(private httpClient: HttpClient) {
    this.getAllCategories().toPromise()
      .then(response => this.allCategories = response.data)
      .catch(error => console.log('Error during loading of all categories', error));
  }

  getCategories(): Observable<DataResponse<Array<Category>>> {
    return this.httpClient.get<any>(`${environment.urlBackEndApi}/category/index`)
      .pipe(map((result) => result));
  }

  getAllCategories(isFinal: boolean = null): Observable<DataResponse<Array<Category>>> {
    let params: string = '';

    if (typeof isFinal === 'boolean') {
      params += params ? `&isFinal=${isFinal}` : `?isFinal=${isFinal}`;
    }

    return this.httpClient.get<any>(`${environment.urlBackEndApi}/category/allCategory${params}`)
      .pipe(map((result) => result));
  }

  getCategory(id: number): Observable<DataResponse<Category>> {
    return this.httpClient.get<any>(`${environment.urlBackEndApi}/category/${id}`)
      .pipe(map((result) => result));
  }

  addCategory(data: Category): Observable<DataResponse<Category>> {
    return this.httpClient.post<any>(`${environment.urlBackEndApi}/category/`, data)
      .pipe(map((result) => result));
  }

  updateCategory(data: Category, id: number): Observable<DataResponse<Category>> {
    return this.httpClient.put<any>(`${environment.urlBackEndApi}/category/${id}`, data)
      .pipe(map((result) => result));
  }

  deleteCategory(id: number): Observable<null> {
    return this.httpClient.delete<any>(`${environment.urlBackEndApi}/category/${id}`)
      .pipe(map((result) => result));
  }

  getJson(): Promise<Blob> {
    return this.httpClient.get(`${environment.urlBackEndApi}/category/getJson`, {responseType: 'blob'}).toPromise();
  }

  updateJson(data): Observable<DataResponse<Category[]>> {
    let formData: any = new FormData();

    formData.append("file", data.file);

    return this.httpClient.post<any>(`${environment.urlBackEndApi}/category/updateJson`, formData)
      .pipe(map((result) => result));
  }
}
