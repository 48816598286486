import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormArray } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
})
export class ConfirmComponent implements OnInit {

  public onClose: Subject<boolean | any[]>;
  public next_questions: FormArray;

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() data: any;

  constructor(
    private bsModalRef: BsModalRef,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.onClose = new Subject();

    this.next_questions = this.fb.array([]);

    this.data?.questions.forEach(question => {
      if (question.next_question_id == this.data.question.id) {
        this.next_questions.push(
          this.fb.group({
            id: [question.id],
            text_question: [question.text_question],
            next_question: [this.data.next_question],
          })
        );
      }

      if (question.is_skip_next_question_id == this.data.question.id) {
        this.next_questions.push(
          this.fb.group({
            id: [question.id],
            text_question: [question.text_question],
            is_skip_next_question: [this.data.is_skip_next_question],
          })
        );
      }

      if (question.answers) {
        question.answers.forEach(answer => {
          if (answer.next_question_id == this.data.question.id) {
            this.next_questions.push(
              this.fb.group({
                id: [question.id],
                text_question: [question.text_question],
                answer_id: [answer.id],
                text_answer: [answer.text_answer],
                next_question: [this.data.next_question],
              })
            );
          }
        });
      }
    });
  }

  public decline() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public accept() {
    this.onClose.next(!this.data ? true : this.next_questions.value);
    this.bsModalRef.hide();
  }

  public dismiss() {
    this.bsModalRef.hide();
  }
}
