import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Question, DataResponse, CategoryQuestions, Lang } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  categoriesQuestions: DataResponse<Array<CategoryQuestions>>;
  selectedQuestion = null;

  constructor(private httpClient: HttpClient) {}

  getQuestions(id: number): Observable<DataResponse<Array<Question>>> {
    return this.httpClient.get<any>(`${environment.urlBackEndApi}/question/index/${id}`)
      .pipe(map((result) => result));
  }

  getCategoriesQuestions(id: number = null, force = true): Observable<DataResponse<Array<CategoryQuestions>>> {

    if (this.categoriesQuestions?.data.length > 0 && !force) {
      return of(this.categoriesQuestions);
    }

    let params: string = '';

    if (id) params += params ? `&id=${id}` : `?id=${id}`;

    return this.httpClient.get<any>(`${environment.urlBackEndApi}/question/categoriesQuestions${params}`)
      .pipe(map((result) => {

        if (!force) {
          this.categoriesQuestions = result;
        }

        return result;
      }));
  }

  getQuestion(id: number): Observable<DataResponse<Question>> {
    return this.httpClient.get<any>(`${environment.urlBackEndApi}/question/${id}`)
      .pipe(map((result) => result));
  }

  addQuestion(data: Question): Observable<DataResponse<Question>> {
    let formData: any = new FormData();

    formData.append("category_id", data.category_id);
    formData.append("next_question_id", data.next_question_id);
    formData.append("is_skip_next_question_id", data.is_skip_next_question_id);
    formData.append("text_question", JSON.stringify(data.text_question));
    formData.append("type_question", data.type_question);
    formData.append("is_start", data.is_start ? 1 : 0);
    formData.append("is_skip", data.is_skip ? 1 : 0);
    formData.append("allow_my_answer", data.allow_my_answer ? 1 : 0);
    formData.append("type_my_answer", data.type_my_answer ?? '');
    formData.append("can_add_files", data.can_add_files ? 1 : 0);
    formData.append("example_answers", JSON.stringify(data.example_answers));
    formData.append("type_values", JSON.stringify(data.type_values));
    formData.append("answers", JSON.stringify(data.answers));
    for (let file of data.files) {
      formData.append("files[]", file);
    }

    return this.httpClient.post<any>(`${environment.urlBackEndApi}/question/`, formData)
      .pipe(map((result) => result));
  }

  updateQuestion(data: Question, id: number): Observable<DataResponse<Question>> {
    let formData: any = new FormData();

    formData.append("category_id", data.category_id);
    formData.append("next_question_id", data.next_question_id);
    formData.append("is_skip_next_question_id", data.is_skip_next_question_id);
    formData.append("text_question", JSON.stringify(data.text_question));
    formData.append("type_question", data.type_question);
    formData.append("is_start", data.is_start ? 1 : 0);
    formData.append("is_skip", data.is_skip ? 1 : 0);
    formData.append("allow_my_answer", data.allow_my_answer ? 1 : 0);
    formData.append("type_my_answer", data.type_my_answer ?? '');
    formData.append("can_add_files", data.can_add_files ? 1 : 0);
    formData.append("example_answers", JSON.stringify(data.example_answers));
    formData.append("type_values", JSON.stringify(data.type_values));
    formData.append("answers", JSON.stringify(data.answers));
    formData.append("deleted", JSON.stringify(data.deleted));
    for (let file of data.files) {
      formData.append("files[]", file);
    }
    formData.append("deletedFiles", JSON.stringify(data.deletedFiles));

    return this.httpClient.post<any>(`${environment.urlBackEndApi}/question/update/${id}`, formData)
      .pipe(map((result) => result));
  }

  deleteQuestion(id: number, data): Observable<null> {
    return this.httpClient.post<any>(`${environment.urlBackEndApi}/question/delete/${id}`, data)
      .pipe(map((result) => result));
  }

  getLinkedQuestions(id: number): Observable<Question[]> {
    return this.httpClient.get<any>(`${environment.urlBackEndApi}/question/linkedQuestions/${id}`)
      .pipe(map((result) => result.data));
  }

  getBrifsCsv(): Promise<Blob> {
    return this.httpClient.get(`${environment.urlBackEndApi}/question/getBrifsCsv`, {responseType: 'blob'}).toPromise();
  }

  addBrifs(data, category_id): Observable<DataResponse<Question[]>> {
    let formData: any = new FormData();

    formData.append("file", data.file);

    return this.httpClient.post<any>(`${environment.urlBackEndApi}/question/addBrifs/${category_id}`, formData)
      .pipe(map((result) => result));
  }

  getJson(locale = null): Promise<Blob> {
    let params: string = '';

    if (locale) params += params ? `&locale=${locale}` : `?locale=${locale}`;

    return this.httpClient.get(`${environment.urlBackEndApi}/question/getJson${params}`, {responseType: 'blob'}).toPromise();
  }

  updateJson(data): Observable<[]> {
    let formData: any = new FormData();

    formData.append("file", data.file);
    formData.append("locale", data.locale);

    return this.httpClient.post<any>(`${environment.urlBackEndApi}/question/updateJson`, formData)
      .pipe(map((result) => result));
  }

  getLangs(): Observable<Array<Lang>> {
    return this.httpClient.get<any>(`${environment.urlBackEndApi}/question/getLangs`)
      .pipe(map((result) => result));
  }
}
