import { NgModule } from '@angular/core';

import { CategoryRoutingModule } from './category-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CategoriesComponent } from './categories/categories.component';
import { TreeComponent } from './tree/tree.component';

@NgModule({
  declarations: [
    CategoriesComponent,
    TreeComponent
  ],
  imports: [
    CategoryRoutingModule,
    SharedModule,
  ]
})
export class CategoryModule { }
