import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Category, DataResponse } from '../../../interfaces';
import { CategoryService } from '../../category/category.service';
import { AlertService } from '../../../services/alert.service';
import { FilterService } from '../filter.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  categories: Category[];
  file;

  constructor(
    private categoryService: CategoryService,
    private filterService: FilterService,
    private loaderService: NgxUiLoaderService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.getCategories();
  }

  private getCategories() {
    this.loaderService.start();
    this.categoryService.getCategories()
      .subscribe((success: DataResponse<Category[]>) => {
        this.categories = success.data;
      }).add(() => {
        this.loaderService.stop();
      });
  }

  getJson() {
    this.loaderService.start();
    this.filterService.getJson()
      .then((success: any) => {
        if (window) {
          const blob = window.URL.createObjectURL(success);
          const link = document.createElement('a');
          link.href = blob;
          link.download = 'filters.json';
          link.click();

          setTimeout(() => {
            window.URL.revokeObjectURL(blob);
            link.remove();
          }, 100);
        }
      }).finally(() => {
        this.loaderService.stop();
      });
  }

  addJson(event) {
    this.file = event.target.files[0];
  }

  updateJson() {
    this.loaderService.start();
    this.filterService.updateJson({file: this.file})
      .subscribe(() => {
        this.alertService.success('Файл успешно загружен.');
        this.file = null;
      }, (error) => {
        this.alertService.danger(error);
      }).add(() => {
        this.loaderService.stop();
      });
  }
}
