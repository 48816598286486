import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataResponse, Category, Question } from '../../../interfaces';
import { CategoryService } from '../../category/category.service';
import { QuestionService } from '../question.service';
import { AlertService } from '../../../services/alert.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  categories: Category[];
  allCategories: Category[] = [];
  category_id = null;
  file;
  json;
  locale = null;
  isHiddenAll = true;

  constructor(
    private categoryService: CategoryService,
    private questionService: QuestionService,
    private loaderService: NgxUiLoaderService,
    private alertService: AlertService,
    private scroller: ViewportScroller,
  ) { }

  ngOnInit() {
    // this.getCategories();
    this.getCategoriesQuestions();
    this.getAllCategories();
  }

  // private getCategories() {
  //   this.loaderService.start();
  //   this.categoryService.getCategories()
  //     .subscribe((success: DataResponse<Category[]>) => {
  //       this.categories = success.data;
  //     }).add(() => {
  //       this.loaderService.stop();
  //     });
  // }

  getBrifsCsv() {
    this.loaderService.start();
    this.questionService.getBrifsCsv()
      .then((success: any) => {
        if (window) {
          const blob = window.URL.createObjectURL(success);
          const link = document.createElement('a');
          link.href = blob;
          link.download = 'brifs.csv';
          link.click();
          console.log(blob);

          setTimeout(() => {
            window.URL.revokeObjectURL(blob);
            link.remove();
          }, 100);
        }
      }).finally(() => {
        this.loaderService.stop();
      });
  }

  getAllCategories() {
    this.loaderService.start();
    this.categoryService.getAllCategories(true)
      .subscribe((success: DataResponse<Category[]>) => {
        this.allCategories = success.data;
      }).add(() => {
        // this.loaderService.stop();
      });
  }

  addFile(event) {
    this.file = event.target.files[0];
  }

  addBrifs() {
    this.loaderService.start();
    this.questionService.addBrifs({file: this.file}, this.category_id)
      .subscribe((success: DataResponse<Question[]>) => {
        this.alertService.success('Бриф успешно загружен.');
        this.file = null;
      }, (error) => {
        this.alertService.danger(error);
      }).add(() => {
        this.loaderService.stop();
      });
  }

  getJson() {
    this.loaderService.start();
    this.questionService.getJson(this.locale)
      .then((success: any) => {
        if (window) {
          const blob = window.URL.createObjectURL(success);
          const link = document.createElement('a');
          link.href = blob;
          link.download = 'categoryQuestions.json';
          link.click();

          setTimeout(() => {
            window.URL.revokeObjectURL(blob);
            link.remove();
          }, 100);
        }
      }).finally(() => {
        this.loaderService.stop();
      });
  }

  addJson(event) {
    this.json = event.target.files[0];
  }

  updateJson() {
    this.loaderService.start();
    this.questionService.updateJson({file: this.json, locale: this.locale})
      .subscribe(() => {
        this.alertService.success('Файл успешно загружен.');
        this.json = null;
      }, (error) => {
        this.alertService.danger(error);
      }).add(() => {
        this.loaderService.stop();
      });
  }

  getCategoriesQuestions(force = false) {
    this.loaderService.start();
    this.questionService.getCategoriesQuestions(null, force)
      .subscribe((success: DataResponse<Category[]>) => {
        this.categories = success.data;

        this.categories.forEach(category => {
          category.settings = {
            hidden: true
          };

          category.children.forEach(children => {
            children.settings = {
              hidden: true
            };
          });
        });
      }).add(async () => {
        this.loaderService.stop();

        if (this.questionService.selectedQuestion) {
          await this.openAll();

          this.scroller.setOffset([0, 55]);
          this.scroller.scrollToAnchor('question-id-' + this.questionService.selectedQuestion);
        }
      });
  }

  async openAll() {
    this.isHiddenAll = !this.isHiddenAll;
    this.categories.forEach(category => {
      category.settings.hidden = this.isHiddenAll;

      category.children.forEach(children => {
        children.settings.hidden = this.isHiddenAll;
      });
    });
  }
}