import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { CategoryQuestions, DataResponse, Question } from '../../../interfaces';
import { AlertService } from '../../../services/alert.service';
import { ConfirmService } from '../../../services/confirm.service';
import { LangService } from '../../../services/lang.service';
import { QuestionService } from '../question.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  addForm: FormGroup;
  questions: Question[];
  categoriesQuestions: CategoryQuestions[];
  isStartExist = false;
  selectIsOpen = false;
  lastTypeQuestion = '';
  errors;
  errorsTemplate;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private loaderService: NgxUiLoaderService,
    private alertService: AlertService,
    private confirmService: ConfirmService,
    private questionService: QuestionService,
    private langService: LangService,
  ) { }

  ngOnInit(): void {
    this.loaderService.start();
    this.addForm = this.fb.group({
      category_id: [null, Validators.required],
      next_question: [null],
      is_skip_next_question: [null],
      text_question: this.fb.group({
        ru: [''],
        en: [''],
        pl: [''],
      }),
      type_question: [null],
      is_start: [null],
      is_skip: [null],
      allow_my_answer: [null],
      type_my_answer: [null],
      can_add_files: [null],
      example_answers: this.fb.array([]),
      type_values: this.fb.array([]),
      answers: this.fb.array([]),
      files: this.fb.array([]),
    });
    this.addForm.get('type_question').valueChanges.subscribe(value => {
      if (['input', 'textarea', 'range', 'int'].includes(value) &&
          ['radio', 'multipleChoise', ''].includes(this.lastTypeQuestion)) {
        this.answers.clear();
      }
      if (['radio'].includes(this.lastTypeQuestion)) {
        this.answers.value.map((v, i) => this.answers.controls[i].patchValue({next_question: null}));
      }
      if (['int', 'input'].includes(this.lastTypeQuestion) && !['int', 'input'].includes(value)) {
        this.type_values.clear();
      }
      this.lastTypeQuestion = value;
    });
    this.addForm.get('is_skip').valueChanges.subscribe(value => {
      if (!value) {
        this.addForm.get('is_skip_next_question').patchValue(null);
      }
    });
    this.addForm.get('allow_my_answer').valueChanges.subscribe(value => {
      if (!value) {
        this.addForm.get('type_my_answer').patchValue(null);
      } else {
        this.addForm.get('type_my_answer').patchValue(1);
      }
    });
    this.activeRoute.params.subscribe((params) => {
      if (params && params.id) {
        this.addForm.patchValue({category_id: params.id});
        this.getQuestions(params.id);
      }
    });
  }

  addLangs() {
    this.loaderService.start();
    this.langService.getLangs().subscribe(success => {
      success?.forEach(answer => {
        this.answers.push(
          this.fb.group({
            next_question: [null],
            text_answer: [answer.name],
            extra_message: [null],
          })
        );
      });
    }).add(() => this.loaderService.stop());
  }

  getQuestions(id: number) {
    this.loaderService.start();
    this.questionService.getQuestions(id)
      .subscribe((success: DataResponse<Question[]>) => {
        this.questions = success.data;
        let question = this.questions.find(question => question.is_start == true);
        if (question?.is_start) {
          this.isStartExist = true;
        }
      }, (error) => {
        this.alertService.danger(error);
        this.router.navigate(['questions']);
      }).add(() => this.loaderService.stop());
    this.loaderService.start();
    this.questionService.getCategoriesQuestions(id)
      .subscribe((success: DataResponse<CategoryQuestions[]>) => {
        this.categoriesQuestions = success.data;
      }, (error) => {
        this.alertService.danger(error);
        this.router.navigate(['questions']);
      }).add(() => this.loaderService.stop());
  }


  isStartClick() {
    if (this.isStartExist && !this.addForm.get('is_start').value) {
      this.addForm.controls.is_start.setValue(false);
      this.confirmService.confirm('Стартовый вопрос уже существует', 'Вы уверены что хотите поменять стартовый вопрос? Начальный вопрос может быть только один')
        .subscribe((confirmed) => {
          if (confirmed && !this.addForm.get('is_start').value) {
            this.addForm.get('is_start').setValue(true);
          }
        });
    }
  }

  get answers() {
    return this.addForm.get('answers') as FormArray;
  }

  get example_answers() {
    return this.addForm.get('example_answers') as FormArray;
  }

  get type_values() {
    return this.addForm.get('type_values') as FormArray;
  }

  get files() {
    return this.addForm.get('files') as FormArray;
  }

  addAnswer() {
    this.answers.push(
      this.fb.group({
        next_question: [null],
        text_answer: this.fb.group({
          ru: [''],
          en: [''],
          pl: [''],
        }),
        extra_message: [null],
      })
    );
  }

  addExampleAnswer() {
    this.example_answers.push(
      this.fb.group({
        ru: [''],
        en: [''],
        pl: [''],
      })
    );
  }

  addTypeValue() {
    this.type_values.push(
      this.fb.group({
        ru: [''],
        en: [''],
        pl: [''],
      })
    );
  }

  add() {
    if (this.addForm.invalid) {
      return;
    }
    this.loaderService.start();

    const data = {
      category_id: this.addForm.value.category_id,
      next_question_id: this.addForm.value.next_question?.id || null,
      is_skip_next_question_id: this.addForm.value.is_skip_next_question?.id || null,
      text_question: this.addForm.value.text_question,
      type_question: this.addForm.value.type_question,
      is_start: this.addForm.value.is_start,
      is_skip: this.addForm.value.is_skip,
      allow_my_answer: this.addForm.value.allow_my_answer,
      can_add_files: this.addForm.value.can_add_files,
      type_my_answer: this.addForm.value.type_my_answer,
      example_answers: this.addForm.value.example_answers,
      type_values: this.addForm.value.type_values,
      answers: [],
      files: this.addForm.value.files,
    };

    data.answers = this.addForm.value.answers.map(answer => {
      return {
        next_question_id: answer.next_question?.id || null,
        text_answer: answer.text_answer,
        extra_message: answer.extra_message,
      };
    });

    this.questionService.addQuestion(data)
      .subscribe((success: DataResponse<Question>) => {
        this.alertService.success('Вопрос добавлен.');
        this.router.navigate(['questions']);
      }, (error) => {
        this.errors = error.error || error;
      }).add(() => this.loaderService.stop());
  }

  deleteAnswer(i: number) {
    this.answers.removeAt(i);
  }

  deleteExampleAnswer(i: number) {
    this.example_answers.removeAt(i);
  }

  deleteTypeValue(i: number) {
    this.type_values.removeAt(i);
  }

  updateFiles(event) {
    const target: HTMLInputElement = event.target;
    let files: File[] = event.target.files;
    for (const file of files) {
      this.files.push(
        this.fb.control(file)
      );
    }
    target.value = null;
  }

  deleteFile(i: number) {
    this.addForm.controls.files.value.splice(i, 1);
  }
}
