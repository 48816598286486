import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuestionRoutingModule } from './question-routing.module';
import { QuestionsComponent } from './questions/questions.component';
import { TreeComponent } from './tree/tree.component';
import { AddComponent } from './add/add.component';
import { SharedModule } from '../shared/shared.module';
import { DetailsComponent } from './details/details.component';
import { QuestionSelectComponent } from '../../components/question-select/question-select.component';


@NgModule({
  declarations: [
    QuestionsComponent,
    TreeComponent,
    AddComponent,
    DetailsComponent,
    // QuestionSelectComponent,
  ],
  imports: [
    CommonModule,
    QuestionRoutingModule,
    SharedModule,
  ]
})
export class QuestionModule { }
