import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { Question, DataResponse, Filter } from '../../../interfaces';
import { AlertService } from '../../../services/alert.service';
import { ConfirmService } from '../../../services/confirm.service';
import { QuestionService } from '../../question/question.service';
import { FilterService } from '../filter.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  updateForm: FormGroup;
  questions: Question[];
  filter: Filter;
  errors;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private loaderService: NgxUiLoaderService,
    private alertService: AlertService,
    private confirmService: ConfirmService,
    private questionService: QuestionService,
    private filterService: FilterService,
  ) { }

  ngOnInit(): void {
    this.loaderService.start();
    this.updateForm = this.fb.group({
      category_id: [null, Validators.required],
      question: [null],
      text: [null],
      need_skill: [null],
      text_skill: [null],
      is_active: [null],
      answers: this.fb.array([]),
    });

    this.activeRoute.params.subscribe((params) => {
      if (params && params.id) {
        this.getFilter(params.id);
      }
    });
    this.loaderService.stop();
  }

  update() {
    if (this.updateForm.invalid) {
      return;
    }
    this.loaderService.start();

    const data = {
      category_id: this.updateForm.value.category_id,
      question_id: this.updateForm.value.question.id,
      text: this.updateForm.value.text,
      text_skill: this.updateForm.value.text_skill,
      is_active: this.updateForm.value.is_active,
      answers: this.updateForm.value.answers,
    };

    this.filterService.updateFilter(data, this.filter.id)
      .subscribe((success: DataResponse<Filter>) => {
        this.alertService.success('Фильтр добавлен.');
        this.router.navigate(['filters']);
      }, (error) => {
        this.errors = error.error;
      });
    this.loaderService.stop();
  }

  get answers() {
    return this.updateForm.get('answers') as FormArray;
  }

  getFilter(id) {
    this.filterService.getFilter(id)
    .subscribe((success: DataResponse<Filter>) => {
      this.filter = success.data;
      this.getQuestions();
      this.patch();
    }, (error) => {
      this.alertService.danger(error);
      this.router.navigate(['filters']);
    });
  }

  delete() {
    this.confirmService.confirm('Удаление фильтра', 'Вы действительно хотите удалить фильтр?', 'Удалить')
      .subscribe((confirmed) => {
        if (confirmed) {
          this.loaderService.start();
          this.filterService.deleteFilter(this.filter.id)
            .subscribe((success: null) => {
              this.alertService.success('Фильтр удален.');
              this.router.navigate(['filters']);
            }, (error) => {
              this.errors = error.error;
            }).add(() => {
              this.loaderService.stop();
            });
        }
      });
  }

  patch() {
    if (this.filter.question.type_question == 'radio' ||
        this.filter.question.type_question == 'multipleChoise') {
      let answers = this.filter.answers.map((answer, i) => {
        let formAnswer = this.fb.group({
          'answer_id': answer.answer_id,
          'is_checked': answer.is_checked,
          'text_answer': [{value: this.filter.question.answers[i].text_answer, disabled: true}],
          'text': answer.text,
        });
        return formAnswer;
      });
      this.updateForm.setControl('answers', this.fb.array(answers));
    } else {
      this.updateForm.setControl('answers', this.fb.array([]));
    }
    this.updateForm.patchValue({
      'category_id': this.filter.category_id,
      'text': this.filter.text,
      'question': this.filter.question,
      'need_skill': this.filter.skill?.text ? true : false,
      'text_skill': this.filter.skill?.text,
      'is_active': this.filter.skill?.is_active,
    });

    this.updateForm.get('need_skill').valueChanges.subscribe(value => {
      if (!value) {
        this.updateForm.get('text_skill').patchValue(null);
        this.updateForm.get('is_active').patchValue(null);
      }
    });

    this.updateForm.get('question').valueChanges.subscribe(question => {
      if (question.type_question == 'radio' ||
          question.type_question == 'multipleChoise') {
        let answers = question.answers.map(answer => {
          let formAnswer = this.fb.group({
            'answer_id': answer.id,
            'is_checked': true,
            'text_answer': [{value: answer.text_answer, disabled: true}],
            'text': answer.text_answer,
          });

          return formAnswer;
        });
        this.updateForm.setControl('answers', this.fb.array(answers));
      } else {
        this.updateForm.setControl('answers', this.fb.array([]));
      }
      this.updateForm.patchValue({
        'text': question.text_question,
      });
    });
  }

  getQuestions() {
    this.questionService.getQuestions(this.filter.category_id)
    .subscribe((success: DataResponse<Question[]>) => {
      this.questions = success.data;
    }, (error) => {
      this.alertService.danger(error);
      this.router.navigate(['filters']);
    });
  }

}
