import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { Category, DataResponse, Filter, Question } from '../../../interfaces';
import { ConfirmService } from '../../../services/confirm.service';
import { CategoryService } from '../../category/category.service';
import { FilterService } from '../filter.service';

@Component({
  selector: 'tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css']
})
export class TreeComponent implements OnInit {

  category: Category;
  filters: Filter[];
  isCategoriesPage: boolean = this.router.url.includes('categories');
  isAdmin: boolean = JSON.parse(localStorage.getItem('userInfo')).role.includes('admin');
  iconCollapse: string = 'fa fa-caret-right';
  isStartExist = false;

  @ViewChildren('child') child: QueryList<TreeComponent>;

  @Input()
  set setCategory(category: Category) {
    this.category = category;
    this.category.settings = {
      hidden: true
    };
  }

  constructor(
    private fb: FormBuilder,
    private categoryService: CategoryService,
    private router: Router,
    private loaderService: NgxUiLoaderService,
    private confirmService: ConfirmService,
    private filterService: FilterService,
  ) {}

  ngOnInit() {}

  async getCategory() {
    this.loaderService.start();
    const success = await this.categoryService.getCategory(this.category.id).toPromise();
    success.data.settings = {
      hidden: this.category.settings.hidden
    };

    this.category = success.data;
    this.loaderService.stop();
  }

  async toggleCollapseCategory(open = false) {
    if (!this.category.children && this.category.settings.hidden) {
      await this.getCategory();
    }
    if (this.category.is_final && this.category.settings.hidden) {
      this.getFilters();
    }
    if (this.category.children && this.category.children.length > 0 ||
        this.filters
    ) {
      this.category.settings.hidden = !this.category.settings.hidden;
    }
    if (open) {
      this.category.settings.hidden = false;
    }
  }

  getFilters() {
    this.loaderService.start();
    this.filterService.getFilters(this.category.id)
      .subscribe((success: DataResponse<Filter[]>) => {
        this.filters = success.data;
        if (this.filters.length > 0) {
          this.category.settings.hidden = false;
        }
      });
    this.loaderService.stop();
  }

}
