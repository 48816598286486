import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit {

  public onClose: Subject<{answer: boolean, i?: number}>;
  i: number;

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  public decline() {
    this.onClose.next({answer: false});
    this.bsModalRef.hide();
  }

  public accept() {
    this.onClose.next({answer: true, i: this.i});
    this.bsModalRef.hide();
  }

  public dismiss() {
    this.bsModalRef.hide();
  }

  select(i: number) {
    this.i = i;
  }
}
